<template>
  <div>
  </div>
</template>

<script>
import useJwt from '@core-md/services/auth/useJwt'
import authorizationsClient from '@core-md/services/authorizations/client'

export default {
  data() {
    return {
    }
  },
  created() {
    // apaga o usuário logado
    useJwt.setToken(null)
    useJwt.setUserData(null)
    this.$store.dispatch('authorizations/initialize')
    authorizationsClient.setCachedAuthorizations([])
    this.$router.push({ name: 'auth-login' })
      .then(() => {
        this.$root.showMessageSuccess(this.$i18n.t('logout.messages.success_detail'), this.$i18n.t('logout.messages.success'), 'CheckIcon')
      })
  },
}
</script>
